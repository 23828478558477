'use client';
import React from 'react';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/navigation';

import {
	useDOPDispatch,
	useDOPSelector,
} from '@/globals/dataProviders/reduxHooks';
import { locationChange } from '@/globals/routing/location/locationChangeActions';
import { getHrefKind } from '@/globals/routing/link/functions/getLinkInfo';
import { getIsNewWindowTargeted } from '@/globals/routing/link/functions/getIsNewWindowTargeted';
import {
	dispatchLinkAction,
	dispatchNewWindowLinkAction,
} from '@/globals/routing/link/actions/dispatchLinkAction';
import { loadingIndicatorCSS, loadingOverlayCSS } from './LoadingIndicator.css';
import { useMainMenuOpened } from './mainMenu/MainMenuContext';

export const LoadingIndicator = () => {
	const router = useRouter();
	const pathname = usePathname();
	const dispatch = useDOPDispatch();
	const loading = useDOPSelector((state) => state.location.loading);
	const [, dispatchOpenedFlyout] = useMainMenuOpened();

	React.useEffect(() => {
		const abortController = new AbortController();
		const handleBackForward = () => {
			dispatch(
				locationChange({
					href: window.location.href,
					hrefKind: 'INTERNAL_SELF', // behave like a anchor link
				})
			);
		};
		window.addEventListener('popstate', handleBackForward, {
			signal: abortController.signal,
		});

		const handleLinkClick = (event: MouseEvent) => {
			if (!(event.target instanceof Element)) return;

			const link = event.target.closest('a');

			if (link == null) return;

			if (link.href === '') return;

			const dataset = { ...link.dataset };

			const linkType = link.getAttribute('data-link-type') || '';

			const oldUrl = new URL(pathname, window.location.origin);
			const newUrl = new URL(link.href);

			const hrefKind = getHrefKind(newUrl, oldUrl);

			const isSameWindowTargeted = !getIsNewWindowTargeted(event);
			if (isSameWindowTargeted) {
				dispatchLinkAction({
					hrefKind,
					url: newUrl,
					linkMeta: { linkType, dataset },
					dispatch,
				});

				if (hrefKind === 'INTERNAL_SELF') {
					event.preventDefault();

					dispatchOpenedFlyout({ type: 'closeMainMenu' });

					const hash = newUrl.hash;
					router.push(hash, { scroll: false });
					if (hash) {
						const target = document.getElementById(hash.slice(1));

						if (target != null) {
							target.focus({ preventScroll: true });
							target.scrollIntoView({ behavior: 'smooth' });
						} else {
							document.body.focus({ preventScroll: true });
							window.scrollTo({ top: 0, behavior: 'smooth' });
						}
					} else {
						document.body.focus({ preventScroll: true });
						window.scrollTo({ top: 0, behavior: 'smooth' });
					}
				}
			} else {
				dispatchNewWindowLinkAction({
					hrefKind,
					url: newUrl,
					linkMeta: { linkType, dataset },
					dispatch,
				});
			}
		};
		document.addEventListener('click', handleLinkClick, {
			capture: true,
			signal: abortController.signal,
		});

		return () => {
			abortController.abort();
		};
	}, [dispatch, dispatchOpenedFlyout, pathname, router]);

	if (!loading) return null;

	return (
		<>
			<i className={loadingOverlayCSS} />
			<i className={loadingIndicatorCSS} />
		</>
	);
};
