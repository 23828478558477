'use client';
import { usePathname } from 'next/navigation';
import React, {
	createContext,
	useContext,
	ReactNode,
	useEffect,
	useReducer,
	useRef,
} from 'react';

type OpenedMenu = {
	mainMenu: boolean;
	submenu: string | undefined;
};
export type ResponsiveMenuType = 'hamburger' | 'navBar';
type MenuAction =
	| {
			type: 'closeSubmenu';
			submenuID: string;
			menuType: ResponsiveMenuType;
	  }
	| {
			type: 'openSubmenu';
			submenuID: string;
			menuType: ResponsiveMenuType;
	  }
	| { type: 'openMainMenu' }
	| { type: 'closeMainMenu' };
const MainMenuContext = createContext<
	| [openedMenu: OpenedMenu, dispatchOpenedMenu: React.Dispatch<MenuAction>]
	| undefined
>(undefined);

const MainMenuBreakpointContext = createContext<number | undefined>(undefined);

export const useMainMenuBreakpoint = () => {
	const context = useContext(MainMenuBreakpointContext);
	if (context == null) {
		throw new Error(
			'useMainMenuBreakpoint must be used within a MainMenuProvider'
		);
	}
	return context;
};

export const useMainMenuOpened = () => {
	const context = useContext(MainMenuContext);
	if (context == null) {
		throw new Error('useMainMenuOpened must be used within a MainMenuProvider');
	}
	return context;
};

const openMainMenuFromNavBar = ({
	mainMenuOpened,
	subMenuOpened,
	menuType,
}: {
	mainMenuOpened: boolean;
	subMenuOpened: boolean;
	menuType: ResponsiveMenuType;
}) => {
	if (menuType === 'hamburger') {
		return mainMenuOpened;
	}

	return subMenuOpened;
};

const reducer = (openedMainMenu: OpenedMenu, action: MenuAction) => {
	switch (action.type) {
		case 'openSubmenu': {
			return {
				submenu: action.submenuID,
				mainMenu: openMainMenuFromNavBar({
					mainMenuOpened: openedMainMenu.mainMenu,
					subMenuOpened: true,
					menuType: action.menuType,
				}),
			};
		}
		case 'closeSubmenu': {
			return {
				submenu: undefined,
				mainMenu: openMainMenuFromNavBar({
					mainMenuOpened: openedMainMenu.mainMenu,
					subMenuOpened: false,
					menuType: action.menuType,
				}),
			};
		}
		case 'openMainMenu': {
			return {
				submenu: openedMainMenu.submenu,
				mainMenu: true,
			};
		}
		case 'closeMainMenu': {
			return {
				submenu: undefined,
				mainMenu: false,
			};
		}
	}
};

export const MainMenuProvider = ({ children }: { children: ReactNode }) => {
	const [openedMainMenu, dispatchOpenedMainMenu] = useReducer(reducer, {
		mainMenu: false,
		submenu: undefined,
	});
	const pathname = usePathname();
	const pathnameRef = useRef<string | undefined>(undefined);

	// Close on new page load
	useEffect(() => {
		if (pathnameRef.current === pathname) return;
		pathnameRef.current = pathname;
		dispatchOpenedMainMenu({ type: 'closeMainMenu' });
	}, [pathname]);

	return (
		<MainMenuContext.Provider value={[openedMainMenu, dispatchOpenedMainMenu]}>
			{children}
		</MainMenuContext.Provider>
	);
};
